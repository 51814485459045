import React, { useState } from 'react';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('https://api.brevo.com/v3/contacts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': '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', 
      },
      body: JSON.stringify({
        email: email,
        listIds: [5], 
      }),
    });

    if (response.ok) {
      setIsSuccess(true);
      setMessage('Your subscription has been successful.');
      setEmail('');
      setIsError(false);
    } else {
      const errorData = await response.json();
      setIsError(true);
      setMessage(`Error: ${errorData.message}`);
      setIsSuccess(false);
    }
  };

  return (
    <div>
      <style>
        {`
          .newsletter-card {
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 20px;
            width: 250px; 
            margin: 20px auto;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            text-align: left;
            background-color: black;
            
          }

          .newsletter-card h2 {
            font-size: 24px;
           
          }

          .newsletter-card h4 {
            font-size: 14px;
            
          }

          .newsletter-card h5 {
            font-size: 12px;
          
          }

          .newsletter-card input {
            padding: 5spx;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 80%; /* Adjust as needed */
          }

          .newsletter-card button {
          margin-top:5px;
            padding: 5px 10px;
            background-color: #0097B2;
            color: black;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }

          .newsletter-card button:hover {
            background-color: #0056b3;
          }

          .success-message {
            color: green;
          }

          .error-message {
            color: red;
          }
        `}
      </style>
      <div className="newsletter-card">
        <h2 style={{color:'white'}}>Newsletter</h2>
        <h4 style={{color:'white'}} className='pt-3'>Subscribe to our newsletter and stay updated.</h4>
        <h5 style={{color:'white'}} className='mt-4'>Enter your email address to subscribe:</h5>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button  type="submit" >Subscribe</button>
        </form>
        {isSuccess && <p className="success-message">{message}</p>}
        {isError && <p className="error-message">{message}</p>}
      </div>
    </div>
  );
};

export default NewsletterForm;
